import { Injectable } from '@angular/core';
import { Toaster } from './toaster.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FullLayoutToasterService {
  public showToaster: BehaviorSubject<Toaster> = new BehaviorSubject({
    visible: false,
  } as Toaster);
}
